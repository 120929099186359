'use client';

import { Image, LoadingOverlay } from "@mantine/core";
import { useloadingStore } from "@/store/store";


export function LoadingStatus() {
    const { loading } = useloadingStore();

    return (
        <LoadingOverlay
            visible={loading}
            zIndex={1000}
            style={{ display: "flex", width: "100%", height: "100vh", justifyContent: "center", alignItems: "center", position: "fixed", top: 0 }}
            loaderProps={{
                children: (
                    <Image h={54} w="auto" fit="contain" src="/loader.svg" alt="loader" />
                )
            }}
        />
    );
}
