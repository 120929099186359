"use client";

import { Box, Button, Container, Group, Image, useMantineColorScheme } from "@mantine/core";
import cls from "./Header.module.css";
import { ToggleMenu } from "../ToggleMenu/ToggleMenu";
import { useAuthStore, useChainStore, useCryptoStore, useFiatStore, useloadingStore, useMenuStore } from "@/store/store";
import { useRouter } from "next/navigation";
import { usePathname } from 'next/navigation';
import { useEffect } from "react";
import { menuList } from "../MenuList";
import { fiatList } from "@/store/fiatList";
import { cryptoList } from "@/store/cryptoList";


export function Header() {
    const router = useRouter();
    const { colorScheme } = useMantineColorScheme();
    const { menu, setMenu } = useMenuStore();
    const { isAuth } = useAuthStore();
    const { loading, setLoading } = useloadingStore();
    const { cryptoSelected, setCryptoSelected } = useCryptoStore();
    const { chainSelected, setChainSelected } = useChainStore();
    const { fiatSelected, setFiatSelected } = useFiatStore();

    const pathname = usePathname();
    const pathArray = pathname.split('/');
    const pagetype = pathArray[1] === 'p2p' && menuList[pathArray[2]] && menuList[pathArray[2]].key ? pathArray[2].toLowerCase() === 'buy' ? 'buy' : "sell" : "buy";
    const cryptosymbol = pathArray[1] === 'p2p' ? pathArray[3].toUpperCase() : 'BTC_ANY';
    const splitparam = cryptosymbol.split('_');
    const cryptoArray = splitparam[0].split('-');
    const cryptoparam = cryptoArray[0] && cryptoList[cryptoArray[0]] ? cryptoArray[0] : 'BTC';
    const chainparam = cryptoArray[1] && cryptoList[cryptoparam].items[cryptoArray[1]] ? cryptoList[cryptoparam].items[cryptoArray[1]].key : 'ETH';
    const fiatparam = splitparam[1] && fiatList[splitparam[1]] ? splitparam[1] : 'ANY';
    //console.log(pathname, pagetype, cryptoparam, fiatparam);
    const selectedMenu = pathArray[1] === '' ? '' : menuList[pathArray[1]] && menuList[pathArray[1]].key ? menuList[pathArray[1]].key : pathArray[1] === 'p2p' ? pagetype : '';

    useEffect(() => {
        setMenu(selectedMenu);
        setCryptoSelected(cryptoparam);
        setChainSelected(chainparam);
        setFiatSelected(fiatparam);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, [])

    return (
        <Box component="header" className={cls.header} >
            <Container size="lg" className={cls.inner}>
                <Box component="a" href="/" onClick={(e) => {
                    e.preventDefault();
                    if (menu != '') {
                        setLoading(true);
                        setMenu('');
                        router.push("/", { scroll: false }); setMenu('');
                        setTimeout(() => {
                            setLoading(false);
                        }, 1000);
                    }
                }}>
                    <Image h={32} w="auto" fit="contain" src="/logo.svg" alt="logo" darkHidden />
                    <Image h={32} w="auto" fit="contain" src="/dark.svg" alt="logo" lightHidden />
                </Box>
                <Group >
                    <Group gap={4} visibleFrom={"sm"} >
                        {
                            Object.values(menuList).map((r, i) => (
                                <Box key={i}>
                                    <Button
                                        visibleFrom={"md"}
                                        key={i}
                                        color={r.key === 'sell' ? "myRed" : colorScheme === 'dark' ? 'myDark' : 'myLight'}
                                        leftSection={r.icon} variant={menu === r.key ? "filled" : "subtle"}
                                        onClick={() => {
                                            if (r.key != menu) {
                                                setLoading(true);
                                                setMenu(r.key);
                                                router.push(r.key === 'buy' || r.key === 'sell' ? Object.values(cryptoList[cryptoSelected].items).length > 0 ? `/p2p/${r.key}/${cryptoSelected}-${chainSelected}_${fiatSelected}` : `/p2p/${r.key}/${cryptoSelected}_${fiatSelected}` : r.key === 'account' ? isAuth ? `/${r.key}` : `/signup` : `/${r.key}`, { scroll: false });
                                                setTimeout(() => {
                                                    setLoading(false);
                                                }, 1000);
                                            }
                                        }}>
                                        {r.key === 'account' ? isAuth ? `Account` : `Sign Up` : r.value}
                                    </Button>
                                    <Button
                                        size={"compact-xs"}
                                        hiddenFrom={"md"}
                                        color={r.key === 'sell' ? "myRed" : colorScheme === 'dark' ? 'myDark' : 'myLight'}
                                        leftSection={r.icon} variant={menu === r.key ? "filled" : "subtle"}
                                        onClick={() => {
                                            if (r.key != menu) {
                                                setLoading(true);
                                                setMenu(r.key);
                                                router.push(r.key === 'buy' || r.key === 'sell' ? Object.values(cryptoList[cryptoSelected].items).length > 0 ? `/p2p/${r.key}/${cryptoSelected}-${chainSelected}_${fiatSelected}` : `/p2p/${r.key}/${cryptoSelected}_${fiatSelected}` : r.key === 'account' ? isAuth ? `/${r.key}` : `/signup` : `/${r.key}`, { scroll: false });
                                                setTimeout(() => {
                                                    setLoading(false);
                                                }, 1000);
                                            }
                                        }}>
                                        {r.key === 'account' ? isAuth ? `Account` : `Sign Up` : r.value}
                                    </Button>
                                </Box>

                            ))
                        }
                    </Group>
                    <ToggleMenu />
                </Group>
            </Container>
        </Box>
    );
}
