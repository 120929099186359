'use client';

import { useAuthStore, useChainStore, useCryptoStore, useFiatStore, useMenuStore } from '@/store/store';
import { ActionIcon, Button, Indicator, Menu, rem, Tooltip, useMantineColorScheme } from '@mantine/core';
import { IconBell, IconHistory, IconLanguage, IconLogin, IconLogout, IconMenu2, IconMoon, IconNotification, IconSettings, IconSun, IconUser, IconUserDollar, IconUserQuestion, IconX } from '@tabler/icons-react';
import { useRouter } from 'next/navigation';
import { menuList } from '../MenuList';
import { cryptoList } from '@/store/cryptoList';
import { useState } from 'react';

export function ToggleMenu() {
    const router = useRouter();
    const { colorScheme, setColorScheme } = useMantineColorScheme();
    //const { menuOpen, setMenuOpen } = useMenuStore();
    const { menu, setMenu } = useMenuStore();
    const { isAuth, setIsAuth } = useAuthStore();
    const { cryptoSelected, setCryptoSelected } = useCryptoStore();
    const { chainSelected, setChainSelected } = useChainStore();
    const { fiatSelected, setFiatSelected } = useFiatStore();
    const [menuOpen, setMenuOpen] = useState<boolean>(false);

    return (
        <ActionIcon.Group>
            <Menu withArrow width={300} openDelay={100} closeDelay={400} onOpen={() => { setMenuOpen(true) }} /* position="bottom-end" offset={20} */ opened={menuOpen} onClose={() => { setMenuOpen(false) }} >
                <ActionIcon variant="default" size="lg" aria-label="Menu" onClick={() => { setMenuOpen(!menuOpen) }}>
                    <Menu.Target>
                        {menuOpen ?
                            <IconX /> :
                            <IconMenu2 />
                        }
                    </Menu.Target>
                    <Menu.Dropdown>
                        <Menu.Item key={"account"} leftSection={<IconUser style={{ width: rem(24), height: rem(24) }} />} onClick={() => { console.log("account") }}>
                            Account
                        </Menu.Item>
                        {
                            Object.values(menuList).map((r, i) => (
                                <Menu.Item
                                    hiddenFrom={"sm"}
                                    key={r.key}
                                    /*color={r.key === 'sell' ? "myRed" : colorScheme === 'dark' ? 'myDark' : 'myLight'} 
                                     variant={menu === r.key ? "filled" : "subtle"}*/
                                    leftSection={r.icon}
                                    onClick={() => {
                                        if (r.key != menu)
                                            router.push(r.key === 'buy' || r.key === 'sell' ? Object.values(cryptoList[cryptoSelected].items).length > 0 ? `/p2p/${r.key}/${cryptoSelected}-${chainSelected}_${fiatSelected}` : `/p2p/${r.key}/${cryptoSelected}_${fiatSelected}` : r.key === 'account' ? isAuth ? `/${r.key}` : `/signup` : `/${r.key}`, { scroll: false });
                                        setMenu(r.key);
                                    }}>
                                    {r.key === 'account' ? isAuth ? `Account` : `Sign Up` : r.value}
                                </Menu.Item>
                            ))
                        }

                        <Menu.Item key={"history"} leftSection={<IconHistory style={{ width: rem(24), height: rem(24) }} />}>
                            History
                        </Menu.Item>
                        <Menu.Item key={"referral"} leftSection={<IconUserDollar style={{ width: rem(24), height: rem(24) }} />}>
                            Referral
                        </Menu.Item>
                        <Menu.Item key={"support"} leftSection={<IconUserQuestion style={{ width: rem(24), height: rem(24) }} />}>
                            Support
                        </Menu.Item>
                        <Menu.Divider key={"divider"} />
                        <Menu.Item key={"dark-theme"} leftSection={<IconMoon style={{ width: rem(24), height: rem(24) }} />} onClick={() => setColorScheme('dark')} darkHidden>
                            Dark Theme
                        </Menu.Item>
                        <Menu.Item key={"light-theme"} leftSection={<IconSun style={{ width: rem(24), height: rem(24) }} />} onClick={() => setColorScheme('light')} lightHidden>
                            Light Theme
                        </Menu.Item>
                        <Menu.Item key={"login-logout"} leftSection={isAuth ? <IconLogout style={{ width: rem(24), height: rem(24) }} /> : <IconLogin style={{ width: rem(24), height: rem(24) }} />} onClick={() => { setIsAuth(!isAuth); if (!isAuth) { setMenu(''); router.push("/login") } }}>
                            {isAuth ? "Logout" : "Login / Signup"}
                        </Menu.Item>
                    </Menu.Dropdown>
                </ActionIcon>
            </Menu>
            {/* <ActionIcon variant="default" size="lg" aria-label="Language"  >
                <IconLanguage />
            </ActionIcon> */}
            {/* <Tooltip label={"Switch to Dark theme"}>
                <ActionIcon variant="default" size="lg" aria-label="DarkTheme" onClick={() => setColorScheme('dark')} darkHidden>
                    <IconMoon />
                </ActionIcon>
            </Tooltip>
            <Tooltip label={"Switch to Light theme"}>
                <ActionIcon variant="default" size="lg" aria-label="LightTheme" onClick={() => setColorScheme('light')} lightHidden>
                    <IconSun />
                </ActionIcon>
            </Tooltip>
            <Tooltip label={isAuth ? "Logout" : "Login"}>
                <ActionIcon variant="default" size="lg" aria-label="Menu" onClick={() => { setIsAuth(!isAuth); if (!isAuth) { setMenu(''); router.push("/login") } }}>
                    {isAuth ?
                        <IconLogout /> :
                        <IconLogin />
                    }
                </ActionIcon>
            </Tooltip> */}
            <Tooltip label={"Notification"}>
                <Indicator label={''} size={16} withBorder processing={false} >
                    <ActionIcon variant="default" size="lg" aria-label="Notification"  >
                        <IconBell />
                    </ActionIcon>
                </Indicator>
            </Tooltip>
        </ActionIcon.Group>
    );
}
