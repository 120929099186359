import(/* webpackMode: "eager" */ "/opt/buildhome/repo/app/global.css");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/components/Footer/Footer.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/opt/buildhome/repo/components/Header/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LoadingStatus"] */ "/opt/buildhome/repo/components/LoadingStatus/LoadingStatus.tsx");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/@mantine/core/esm/components/Container/Container.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Text"] */ "/opt/buildhome/repo/node_modules/@mantine/core/esm/components/Text/Text.mjs");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/@mantine/core/esm/core/Box/Box.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["ColorSchemeScript"] */ "/opt/buildhome/repo/node_modules/@mantine/core/esm/core/MantineProvider/ColorSchemeScript/ColorSchemeScript.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["MantineProvider"] */ "/opt/buildhome/repo/node_modules/@mantine/core/esm/core/MantineProvider/MantineProvider.mjs");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/@mantine/core/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["theme"] */ "/opt/buildhome/repo/store/theme.ts");
